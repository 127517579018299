import React from 'react'

import AboutUsTitle from '../components/AboutUsTitle/AboutUsTitle'
import aboutUsData from '../data/aboutUsData'
import steps from '../assets/images/steps.png'
import MembersContainer from '../components/MembersContainer/MembersContainer'

function About() {
  return (
    <div className='flex flex-col items-center mt-2 overflow-hidden'>
      <p className='text-1xl lg:text-2xl mt-5 p-5 leading-loose lg:leading-10 text-justify max-w-7xl'>
        <div>
          <AboutUsTitle text='Overview' />
          <p>{aboutUsData?.overview}</p>
        </div>

        <div className='mt-5'>
          <AboutUsTitle text='The Company' />
          <p>{aboutUsData?.about}</p>
        </div>
        <div className='mt-5'>
          <AboutUsTitle text='The Big Picture' />
          <div className='border-y-4 border-indigo-500 mt-4 flex justify-between flex-wrap px-2 py-5'>
            {aboutUsData?.picture?.map((item) => (
              <div key={item.description} className='w-80 text-center'>
                <span className='text-3xl font-semibold'>{item?.title} :</span>
                <p>{item?.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='mt-5'>
          <AboutUsTitle text='Background' />
          <p>{aboutUsData?.background}</p>
        </div>

        <div className='mt-5'>
          <AboutUsTitle
            text='Steps Figure-out'
            className='underline text-center'
          />
          <div className='mt-5'>
            <img src={steps} alt='steps.png' />
          </div>
        </div>
        <div className='mt-12'>
          <AboutUsTitle text='Goals and Targets' />
          <p>{aboutUsData?.goals}</p>
        </div>

        <div className='mt-12 text-black'>
          <MembersContainer
            title='The Leadership'
            memberDetails={aboutUsData?.leadership}
          />
          <MembersContainer
            className='mt-12'
            title='Our Mentors'
            memberDetails={aboutUsData?.mentors}
          />
          <MembersContainer
            className='mt-12'
            title='Our Management Teams'
            memberDetails={aboutUsData?.managementTeams}
          />
        </div>
        <div className='mt-5'>
          <AboutUsTitle text='For More Information' />
          <p>{aboutUsData?.moreInfo}</p>
        </div>
        <div className='mt-5'>
          <span>
            For inquiries and clarification, reach out to,
            <br />
            <a
              href='mailto:https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=talentexploriz@gmail.com'
              target='__blank'
              className='underline'
            >
              talentexploriz@gmail.com
            </a>
            Thank You!
          </span>
        </div>
      </p>
    </div>
  )
}

export default About
