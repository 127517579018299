import React, { useState } from 'react'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import { toast } from 'react-toastify'
import styles from './style.module.css'
import trimAndLowerCase from '../../utils/nameUtil'

function GlobalSearchBar() {
  const [users, setUsers] = useState([])
  const handleUserFilter = _debounce((e) => {
    const name = e.target.value
    const searchName = trimAndLowerCase(name)
    if (!searchName) {
      return setUsers([])
    }
    return axios
      .get(`${process.env.REACT_APP_API_URL}/users/search?name=${searchName}`)
      .then((response) => setUsers(response?.data || []))
      .catch((err) => toast.error(err.message))
  }, 1000)

  return (
    <div className='relative z-10'>
      <input
        placeholder='Search Participants'
        className='px-2 py-1 rounded bg-transparent border-2 outline-0 border-blue-400'
        onChange={handleUserFilter}
      />
      {!!users?.length && (
        <div
          className={`absolute w-full bg-white p-2 h-40 ${styles.searchBar}`}
        >
          {users.map((user) => (
            <button
              key={user?.id}
              type='button'
              className='p-1 w-full rounded text-black mt-1 cursor-pointer hover:bg-slate-300 text-start'
              onClick={() => {
                window.location.href = `user/${user.id}`
              }}
            >
              {user.name}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default GlobalSearchBar
