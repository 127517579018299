import React from 'react'

function TFoot() {
  return (
    <tfoot>
      <tr>
        <th className=' font-bold text-lg'>Name</th>
        <th className=' font-bold text-lg'>Email</th>
        <th className=' font-bold text-lg'>Phone</th>
        <th className=' font-bold text-lg'>Role</th>
        <th className=' font-bold text-lg'>Joined</th>
        <th className=' font-bold text-lg'>Verified</th>
        <th className=' font-bold text-lg'>Rating</th>
        <th className=' font-bold text-lg'>Coins</th>
        <th className=' font-bold text-lg'> Copy </th>
        <th className=' font-bold text-lg'>Edit</th>
        <th className=' font-bold text-lg'>Delete</th>
      </tr>
    </tfoot>
  )
}

export default TFoot
